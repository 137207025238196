/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a:hover {
    @apply text-text-link;
  }

  @font-face {
    font-family: 'Almarena';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/almarena_regular.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Almarena';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/almarena_bold.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Almarena Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/almarena_mono_light.woff2) format('woff2');
  }
}

.hideSrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE and Edge */
}

/* Chrome, Safari and Opera */
.hideSrollbar::-webkit-scrollbar {
  display: none;
}

:root {
  --color-primary: #FF5D64;
  --color-background-header: #fff;
  --color-background-footer: #fff;
}

@layer utilities {
  .colorpicker {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
  }

  .colorpicker::-webkit-color-swatch {
    border-radius: 0.375rem;
    border: 1px solid #e5e7eb;
  }

  .colorpicker::-moz-color-swatch {
    border-radius: 0.375rem;
    border: 1px solid #e5e7eb;
  }
}
